var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"easyPrint",staticClass:"ml-0",staticStyle:{"max-width":"8in","background-color":"transparent"},attrs:{"id":"to_hit_cards_id"}},[(_vm.pages.length > 0)?_c('div',{staticStyle:{"max-width":"8in"}},_vm._l((_vm.pages),function(pg,index1){return _c('div',{key:index1,staticClass:"page ml-0"},[_vm._v(" "+_vm._s(index1)+" "),_c('v-container',_vm._l((_vm.rows),function(row,index2){return _c('v-row',{key:index2,staticClass:"justify-space-around",staticStyle:{"width":"100%","height":"3.1in"},attrs:{"align-items:":"","start":""}},_vm._l((_vm.cols),function(col,index3){return _c('v-col',{key:index3,staticClass:"dc_container"},[_c('div',{staticClass:"dicecard d-flex flex-column justify-center align-center mt2"},[_c('small',[_vm._v("Beta FX©")]),_c('small',{staticClass:"mb-4"},[_vm._v("3d6 Dicecard")]),_c('p',{staticStyle:{"font-size":"26px","font-weight":"800"}},[_c('img',{staticStyle:{"height":"0.35in","width":"auto"},attrs:{"src":require("../images/tohit_50.png")}}),_c('span',{class:[
                    _vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1]
                      .tohit == 18 ||
                    _vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1]
                      .tohit == 3
                      ? _vm.rr.toString()
                      : '',
                  ],staticStyle:{"position":"relative","top":"-6px"}},[_vm._v(": "+_vm._s(_vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1].tohit)+" ")])]),_c('p',{staticStyle:{"font-size":"26px","font-weight":"800"}},[_c('img',{staticStyle:{"height":"0.5in","width":"auto","transform":"scaleX(-1)"},attrs:{"src":require("../images/noun-skill-shield-penetrate-2360219.svg")}}),_c('span',{class:[
                    _vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1]
                      .penatrate == 18 ||
                    _vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1]
                      .penatrate == 3
                      ? _vm.rr.toString()
                      : '',
                  ],staticStyle:{"position":"relative","top":"-19px"}},[_vm._v(": "+_vm._s(_vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1] .penatrate)+" ")])]),_c('p',{staticStyle:{"font-size":"26px","font-weight":"800"}},[_c('img',{staticStyle:{"height":"0.4in","width":"auto"},attrs:{"src":require("../images/noun-damage-5458085.svg")}}),_c('span',{class:[
                    _vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1]
                      .damage == 18 ||
                    _vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1]
                      .damage == 3
                      ? _vm.rr.toString()
                      : '',
                  ],staticStyle:{"position":"relative","top":"-14px"}},[_vm._v(": "+_vm._s(_vm.all3x3d6Rolls[index1 * 9 + index2 * 3 + index3 + 1].damage)+" ")])]),_c('v-spacer'),_c('small',{staticClass:"mr-1",staticStyle:{"width":"100%","text-align":"right"}},[_vm._v(_vm._s(index1 * 9 + index2 * 3 + index3 + 1))])],1)])}),1)}),1)],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }